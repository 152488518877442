<template>
  <PageWithLayout
    :isTitle="true">
    <strong class="tit_term">동의서</strong>
    <p class="desc_term">00. 본인확인 서비스 약관</p>
    <ul>
      <li> <router-link :to="{name : 'IdentityVerifyTerms' }">통신사 본인확인 서비스 이용약관</router-link> </li>
      <li> <router-link :to="{name : 'IdentityVerifyKcbTerms'}">KCB 본인확인 서비스 이용약관</router-link> </li>
      <li> <router-link :to="{name : 'IdentityVerifyPrivacyAgreement'}">개인정보 수집, 이용 및 위탁 동의</router-link> </li>
      <li> <router-link :to="{name : 'IdentityVerifyIdentificationAgreement'}">고유식별정보 처리 동의</router-link> </li>
      <li> <router-link :to="{name : 'IdentityVerify3rdParties'}">알뜰폰 개인정보 제공 동의</router-link> </li>
    </ul>
    <p class="desc_term">01. 법정대리인 동의</p>
    <ul>
      <li> <router-link :to="{name : 'GuardianAgreementForSignup'}">서비스가입</router-link> </li>
      <li> <router-link :to="{name : 'GuardianAgreementForAccountDeletion'}">서비스탈퇴</router-link> </li>
      <li> <router-link :to="{name : 'GuardianAgreementForIssueCard'}">카드발급</router-link> </li>
    </ul>
    <p class="desc_term">02. 광고 마케팅 수신 동의</p>
    <ul>
      <li> <router-link :to="{name : 'ConsentForMarketing'}">광고 마케팅 수신 동의</router-link> </li>
    </ul>
    <p class="desc_term">03. 소득공제 신청 동의</p>
    <ul>
      <li> <router-link :to="{name : 'ConsentForTaxSelttlement'}">소득공제 신청 동의</router-link> </li>
    </ul>
    <strong class="tit_term">개인정보 관련</strong>
    <p class="desc_term">11. 개인정보 처리방침</p>
    <ul>
      <li>
        <router-link :to="{name : 'PrivacyPolicy'}">개인정보 처리방침</router-link>
        <ul>
          <li><router-link :to="{name : 'PrivacyPolicy230327'}">개인정보 처리방침 (23.03.27 시행)</router-link></li>
          <li><router-link :to="{name : 'PrivacyPolicy240227'}">개인정보 처리방침 (24.02.27 시행)</router-link></li>
        </ul>
      </li>
      <li> <router-link :to="{name : 'PrivacyAgreement'}">개인정보 수집 이용 동의</router-link> </li>
      <li> <router-link :to="{name : 'Privacy3rdParties'}">개인정보 제3자 제공 동의</router-link> </li>
      <li> <router-link :to="{name : 'PrivacyAgreementSchoolMeal'}">개인정보 수집 이용 동의_급식정보</router-link> </li>
      <li> <router-link :to="{name : 'PrivacyAgreementBill'}">개인정보 수집 이용 동의_유료서비스</router-link> </li>
    </ul>
    <strong class="tit_term">금감원 신고 약관</strong>
    <ul>
      <li> <router-link :to="{name : 'ElectronicFinancialTerms'}">전자금융거래 이용약관</router-link> </li>
      <li> <router-link :to="{name : 'FirfinMoneyTerms'}">퍼핀머니 이용약관</router-link> </li>
      <li> <router-link :to="{name : 'FirfinPayTerms'}">퍼핀페이 서비스 이용약관</router-link> </li>
      <li> <router-link :to="{name : 'FirfinCardTerms'}">퍼핀카드 이용약관</router-link> </li>
    </ul>
    <strong class="tit_term">제휴사 약관</strong>
    <ul>
      <li> <router-link :to="{name : 'RailplusTerms'}">레일플러스 이용약관</router-link> </li>
    </ul>
    <strong class="tit_term">유료서비스</strong>
    <ul>
      <li> <router-link :to="{name : 'Bill'}">퍼핀 유료서비스 이용약관</router-link> </li>
      <li> <router-link :to="{name : 'BillImweb'}">퍼핀 유료서비스 이용약관(아임웹 판매페이지용)</router-link> </li>
    </ul>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';

export default {
  name:'TermIndex',  
  components:{
    PageWithLayout
  },
}
</script>
<style scoped>
.tit_term{display:block;padding:12px 20px;font-size:20px;line-height:24px;color:#111}
.desc_term{padding:0 20px;color:#666}
ul + .tit_term{margin-top:12px;border-top:1px solid #ddd}
ul + .desc_term{margin-top:12px}

ul a{display:block;padding:8px 40px;text-decoration:underline;color:#232323}
ul ul{padding-left:16px}
</style>